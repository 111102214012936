import axios from "axios";


const baseUrl = "https://crm.jisudonghua.com/voicetransferapis/texttosound/"
let token = localStorage.getItem("token")
axios.interceptors.request.use(config => {
      config.url = config.url.replace('http://', 'https://');
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      if(token){
        config.headers['token'] = token;
      }
    return config;
  });
// 添加响应拦截器
axios.interceptors.response.use(
    response => {
      // 请求成功，直接返回数据
      return response;
    },
    error => {
      // 响应错误处理
      if (error && error.response) {
        console.log(error.response);
        //根据错误码进行不同处理
        switch (error.response.data.code) {
            // 后端说的401/402是token不可用的情况
          case 401:
            localStorage.removeItem("token")
            localStorage.removeItem("userInfo")
            location.href = location.origin
            break;
          case 402:
            localStorage.removeItem("token")
            localStorage.removeItem("userInfo")
            location.href = location.origin
            break;
          default:
            // 其他错误的默认处理逻辑
        }
      }
    }
  );
// 获取主播列表
export const getTypeList = (params) => {
    return  axios.post(baseUrl + "getTypeList",params)
}
// 主播查询
export const queryAnchor = (params) => {
    return  axios.post(baseUrl + "getVoiceList",params)
}

// 文字转语音
export const ttsPost = (params) => {
    return  axios.post(baseUrl + "requestLongTts4Post",params)
}

// 文字转语音轮询结果
export const ttsPostTask = (params)=>{
    return axios.post(baseUrl + "waitLoop4Complete",params)
}
// 获取用户上传的背景音乐列表
export const getUserBgm = (params) => {
    return  axios.post(baseUrl + "getMusicListByUserId",params)
}

// 背景音乐上传
export const uploadBgm = (params)=>{
    let forms = new FormData();
	forms.append("audio", params.file);
	forms.append("user_id", params.user_id);
    return axios.post(baseUrl + "uploadBackgroundMusic",forms)
}

// 获取草稿/作品
export const getText = (params) => {
    return  axios.post(baseUrl + "getPortfolioOrDraftList",params)
}

// 保存草稿/作品
export const saveText = (params) => {
    return  axios.post(baseUrl + "saveOrEditDraft",params)
}

// 删除草稿/作品
export const deleteText = (params) => {
    return  axios.post(baseUrl + "deleteDraft",params)
}

// 获取token
export const authAccessToken = (params) => {
    return  axios.post("https://crm.jisudonghua.com/voicetransferapis/wxauthorization/authAccessToken",params)
}

