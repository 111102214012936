<template>
  <div id="home">
    <HomeHeader v-if="showHF" />
    <router-view />
    <HomeFooter v-if="showHF" />
  </div>
</template>

<script>
import HomeHeader from "@/components/HomeHeader.vue";
import HomeFooter from "@/components/HomeFooter.vue";
import { authAccessToken, getTypeList } from "@/assets/js/ttsRequest";
import { mapMutations } from "vuex";
export default {
  components: { HomeHeader, HomeFooter },
  computed: {
    showHF() {
      let temp = ["/", "/artificialDubbing"];
      return temp.includes(this.$route.path);
    },
  },
  methods: {
    ...mapMutations(["SET_USERINFO"]),
    GetRequest() {
      let url = location.search; //获取url中"?"符后的字符串
      let theRequest = new Object();
      if (url.indexOf("?") != -1) {
        let str = url.slice(1);
        let strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
  },
  mounted() {
    let args = this.GetRequest();
    if (args.code) {
      //扫码跳转回调
      authAccessToken({ code: args.code })
        .then((res) => {
          console.log(res.data);
          let token = res.headers["token"];
          let userInfo = {
            token: token,
            ...res.data,
          };
          localStorage.setItem("token", token);
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          this.SET_USERINFO(userInfo);
          location.href =
            sessionStorage.getItem("href") || "https://sousou.zaiguwang.com/";
        })
        .catch((err) => {
          console.error(err);
        });
    }
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      this.SET_USERINFO(userInfo);
      //现有的token过期很快 没有提供专门的请求去验证token
      //所以随便调用一个请求 用以验证token过期则清空登录数据 重新登录
      getTypeList()
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        });
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/css/common.scss";
#home {
  width: 100%;
}
</style>
