<template>
  <div class="footer m1400">
    <div class="footer_content w1400">
      <img class="logo" src="@/assets/img/homePage/logo.png" alt="" />
      <div class="item column">
        <span class="title">创作工具</span>
        <span class="suttitle">智能配音</span>
        <span class="suttitle">真人配音</span>
      </div>
      <div class="item column">
        <span class="title">协议与规则</span>
        <span class="suttitle">平台交易规则</span>
        <span class="suttitle">法律声明</span>
        <span class="suttitle">隐私政策</span>
      </div>
      <div class="item column">
        <span class="title">用户支持</span>
        <span class="suttitle">电子邮件：sousou@sousou.com</span>
        <span class="suttitle">商务电话：010-8888 8888</span>
      </div>
      <div style="margin-left: auto" class="code_area column">
        <div class="code_con"></div>
        <span>微信扫码咨询</span>
      </div>
      <div style="margin-left: 32px" class="code_area column">
        <div class="code_con"></div>
        <span>小程序配音</span>
      </div>
    </div>
  </div>
</template>
          
          <script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
    <style lang="scss" scoped>
.footer {
  height: 258px;
  background: #262627;
  .footer_content {
    display: flex;
    padding-top: 64px;
    .logo {
      width: 74px;
      height: 22px;
      margin-right: 240px;
    }
    .item {
      margin-right: 100px;

      .title {
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        margin-bottom: 16px;
      }
      .suttitle {
        font-weight: 300;
        font-size: 14px;
        color: #ffffff;
        margin-bottom: 8px;
      }
    }
    .code_area {
      font-size: 14px;
      color: #dbdbdc;
      align-items: center;
      .code_con {
        width: 92px;
        height: 92px;
        background: #363636;
        border-radius: 4px;
        margin-bottom: 16px;
      }
    }
  }
}
</style>